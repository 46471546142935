const APIService = require('../APIService');
const urlParams = require('../../../utils/urlParams');
class ExamTypes extends APIService {
  constructor({ facilityId, id, specialtyId } = { facilityId: null, id: null, specialtyId: null }) {
    super();
    this._url = facilityId
      ? `/clinic-definition/facilities/${facilityId}/specialties/${specialtyId}/exam-types${id ? `/${id}` : ''}`
      : `/clinic-definition/exam-types${id ? `/${id}` : ''}`;
  }

  async get({ parameters }) {
    try {
      const { data } = await this.httpClient.get(`${this._url}${urlParams(parameters)}/?is_enabled=all&limit=1000`);
      return data.results;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getEnabled() {
    try {
      const { data } = await this.httpClient.get(`${this._url}/?is_enabled=true`);
      return data.results;
    } catch (error) {
      throw new Error(error);
    }
  }

  async create({ payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}`, payload);
      return data;
    } catch (error) {
      throw new Error(error.response?.data.name || error);
    }
  }

  async delete({ id }) {
    try {
      const { data } = await this.httpClient.delete(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async patch({ payload }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/${payload.id}/?is_enabled=all`, payload);
      return data;
    } catch (error) {
      throw new Error(error.response?.data.name || error);
    }
  }

  async enableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/enable-all`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async disableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/disable-all`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async enable() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/enable`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async disable() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/disable`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

module.exports = ExamTypes;
